export const initBannerTimer = () => {
    // // VARS
    const deadline = new Date(Date.parse(new Date()) + 43200 * 1000);

    // INITS
    setClock('[data-timer]', deadline);

    // FUNCTIONS
    function getTimeRemaining(endtime) {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 12);

        return {
            total: total,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        };
    }

    function getZero(num) {
        if (num >= 0 && num < 10) {
            return '0' + num;
        } else {
            return num;
        }
    }

    function getZeroHours(num) {
        if (num >= 0 && num < 10) {
            return '12';
        } else {
            return num;
        }
    }

    function setClock(selector, endtime) {
        const timer = document.querySelector(selector);
        if (!timer) return;
        const hours = timer.querySelector('[data-timer-hours]');
        const minutes = timer.querySelector('[data-timer-minutes]');
        const seconds = timer.querySelector('[data-timer-seconds]');
        const timeInterval = setInterval(updateClock, 1000);

        updateClock();

        function updateClock() {
            const timer = getTimeRemaining(endtime);

            hours.textContent = getZeroHours(timer.hours);
            minutes.textContent = getZero(timer.minutes);
            seconds.textContent = getZero(timer.seconds);

            if (timer.total <= 0) {
                clearInterval(timeInterval);
                const deadline = new Date(Date.parse(new Date()) + 43200 * 1000);
                setClock('[data-timer]', deadline);
            }
        }
    }
};
