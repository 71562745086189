import { scrollTo } from '../helper-function/scrollTo';

export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const menuLinks = document.querySelector('[data-menu-link-list]');
    const activeClassMobileMenu = 'mobile-menu-active';
    if (!hamburger || !menuLinks) return;
    const anchorLinks = menuLinks.querySelectorAll('[data-anchore]');

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    [...anchorLinks].forEach((link) => {
        link.addEventListener('click', handleOnClickAnchorLink, false);
    });

    window.addEventListener('resize', handleOnResize, false);

    // HANDLERS
    function handleOnClickHamburger() {
        toggleMobileMenu();
    }

    function handleOnClickAnchorLink(event) {
        if (menuLinks.dataset.menuLinkList === 'home') {
            event.preventDefault();
        }

        closeMobileMenu();
        window.hamburger.close();
        scrollTo(this.dataset.anchore, 0);
    }

    function handleOnResize() {
        closeMobileMenuOnResize();
    }

    // FUNCTIONS
    function toggleMobileMenu() {
        if (menuLinks.classList.contains(activeClassMobileMenu)) {
            closeMobileMenu();
        } else {
            openMobileMenu();
        }
    }

    function openMobileMenu() {
        menuLinks.classList.add(activeClassMobileMenu);
    }

    function closeMobileMenu() {
        menuLinks.classList.remove(activeClassMobileMenu);
    }

    function closeMobileMenuOnResize() {
        if (window.innerWidth > 780 && menuLinks.classList.contains(activeClassMobileMenu)) {
            closeMobileMenu();
            window.hamburger.close();
        }
    }
};
