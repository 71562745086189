import { insertScript } from '../../../../../Common/Resources/src_front/js/helper-functions/insertScript';

export const initChunkPageBeforeCheckout = () => {
    const pricesScript = document.querySelector('[data-prices-script-scr]');
    const style = document.querySelector('[data-href-style]');
    if (!pricesScript && !style) {
        window.pageLoader.hide();
        return;
    }

    if (pricesScript) {
        const url = pricesScript.dataset.pricesScriptScr;
        const script = insertScript(url);

        script.addEventListener('load', function () {
            window.pageLoader.hide();
        });
    }
};
