import { percentCounter } from '../../components/percentCounter';

export const initSnapchatHacking = (modal) => {
    // VARS
    const modalWrap = document.querySelector('[data-modal-wrap]');
    if (!modalWrap) return;
    const snapChat = document.querySelector('[data-snapchat]');
    if (!snapChat) return;
    const form = document.querySelector('[data-nickname-form]');
    if (!form) return;
    const input = document.querySelector('[data-validate-field]');
    const errorLength = document.querySelector('[data-error-length]');
    const submitSpaces = document.querySelector('[data-error-spaces]');
    const submitStart = document.querySelector('[data-error-start]');
    const errorCharacters = document.querySelector('[data-error-characters]');
    const errorSymbols = document.querySelector('[data-error-symbols]');

    // LISTENERS
    form.addEventListener('submit', submitHandler, false);

    // HANDLERS
    function submitHandler(e) {
        e.preventDefault();
        if (isValidInput(input.value)) {
            setUrlData();
            hideAllErrors();
            modal.open('success-send');
            setTimeout(() => {
                input.value = '';
            }, 500);
        }
    }

    // FUNCTIONS & METHODS
    function isValidInput(value) {
        hideAllErrors();
        if (value.indexOf('@') === 0) {
            const username = value.substr(1);
            if (!isValidLength(username)) {
                return false;
            } else if (!isValidSpaces(username)) {
                return false;
            } else if (!isValidCharacters(username)) {
                return false;
            } else if (!isValidStart(username)) {
                return false;
            } else if (!hasInvalidSymbols(username)) {
                return false;
            }
        } else {
            if (!isValidLength(value)) {
                return false;
            } else if (!isValidSpaces(value)) {
                return false;
            } else if (!isValidCharacters(value)) {
                return false;
            } else if (!isValidStart(value)) {
                return false;
            } else if (!hasInvalidSymbols(value)) {
                return false;
            }
        }
        return true;
    }

    function hideAllErrors() {
        errorLength.classList.add('hide');
        submitSpaces.classList.add('hide');
        submitStart.classList.add('hide');
        errorCharacters.classList.add('hide');
        errorSymbols.classList.add('hide');

        errorLength.classList.remove('is-error');
        submitSpaces.classList.remove('is-error');
        submitStart.classList.remove('is-error');
        errorCharacters.classList.remove('is-error');
        errorSymbols.classList.remove('is-error');
    }

    function isValidLength(value) {
        if (value.length < 3 || value.length > 15) {
            errorLength.classList.remove('hide');
            errorLength.classList.add('is-error');
            return false;
        }
        return true;
    }

    function isValidSpaces(value) {
        if (value.includes(' ')) {
            submitSpaces.classList.remove('hide');
            submitSpaces.classList.add('is-error');
            return false;
        }
        return true;
    }

    function isValidCharacters(value) {
        const regex = /^[A-Za-z0-9_.-]+$/;
        if (!regex.test(value)) {
            errorCharacters.classList.remove('hide');
            errorCharacters.classList.add('is-error');
            return false;
        }
        return true;
    }

    function isValidStart(value) {
        const firstChar = value.charAt(0);
        const regex = /^[A-Za-z]$/;
        if (!regex.test(firstChar)) {
            submitStart.classList.remove('hide');
            submitStart.classList.add('is-error');
            return false;
        }
        return true;
    }

    function hasInvalidSymbols(value) {
        const regex = /^[A-Za-z0-9_.-]+$/;
        if (regex.test(value)) {
            return true;
        } else {
            errorSymbols.classList.remove('hide');
            errorSymbols.classList.add('is-error');
            return false;
        }
    }

    function setUrlData() {
        const result = document.querySelector('[data-result]');

        if (!input || !result) return;

        let username = input.value;
        if (username.indexOf('@') !== 0) {
            username = '@' + username;
        }

        result.textContent = username;
    }
};

export const initSnapchatHackingProcess = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-snapchat]')) return;

    document.querySelector('[data-submit-hack]')?.addEventListener('click', (e) => {
        modal.open('success-send-review');
        percentCounter(70);
        setTimeout(() => {
            modal.open('success-send-review-last');
        }, 8000);
    });
};
