import { stickyElem } from '../helper-function/stickyElem';

export const initStickyMenu = () => {
    // VARS
    const stickyBlock = document.querySelector('[data-sticky-block]');
    const sticky = document.querySelector('[data-sticky]');
    if (!stickyBlock || !sticky) return;

    // INITS
    stickyElem(sticky, 0, setStyleStickyMenu, removeStyleStickyMenu);

    // FUNCTIONS
    function setStyleStickyMenu() {
        sticky.style.marginBottom = stickyBlock.scrollHeight + 'px';
        stickyBlock.classList.add('block--sticky');
    }

    function removeStyleStickyMenu() {
        sticky.style.marginBottom = '';
        stickyBlock.classList.remove('block--sticky');
    }
};
