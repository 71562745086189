import { percentCounter } from '../percentCounter';
import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export const initFacebookHacking = (modal) => {
    // VARS
    const input = document.querySelector('[data-validate-field]');
    const error = document.querySelector('[data-input-error]');
    const submit = document.querySelector('[data-submit]');
    const planForm = document.querySelector('[data-hack-plan-form]');
    if (!planForm) return;
    const email = planForm.querySelector('[data-plan-form-email]');
    const errorRequired = planForm.querySelector('[data-error-required]');
    const errorInvalid = planForm.querySelector('[data-error-invalid]');
    if (!submit) return;

    // LISTENERS
    submit.addEventListener('submit', (e) => {
        e.preventDefault();
        if (isValidUrl(input.value)) {
            setUrlData();
            error.classList.add('hide');
            error.classList.remove('is-error');
            modal.open('success-send');
            percentCounter(50);
            setTimeout(() => {
                input.value = 'https://www.facebook.com/';
                modal.open('success-send-review');
            }, 8500);
        } else {
            error.classList.remove('hide');
            error.classList.add('is-error');
        }
    });

    planForm.addEventListener('submit', handleOnSubmitPlanForm);

    email.addEventListener('focus', handleOnActionEmailInput);

    email.addEventListener('input', handleOnActionEmailInput);

    // HANDLERS
    function handleOnSubmitPlanForm(event) {
        event.preventDefault();

        if (!email.value.trim().length) {
            errorRequired.textContent = errorRequired.dataset.errorRequired;
            errorRequired.classList.add('is-error');
            email.classList.add('is-error');
        } else if (!isValidMail(email.value.trim())) {
            errorInvalid.textContent = errorInvalid.dataset.errorInvalid;
            errorInvalid.classList.add('is-error');
            email.classList.add('is-error');
        } else {
            redirect(planForm);
        }
    }

    function handleOnActionEmailInput() {
        this.classList.remove('is-error');
        errorInvalid.classList.remove('is-error');
        errorRequired.classList.remove('is-error');
    }

    // FUNCTIONS
    function isValidUrl(value) {
        let isValid = false;
        const str = `https://www.facebook.com/`;
        if (value.indexOf(str) !== -1 && value.length > 27) isValid = true;
        return isValid;
    }

    function setUrlData() {
        const inputEmail = document.querySelector('[data-validate-field]');
        const result = document.querySelector('[data-result]');
        const result2 = document.querySelector('[data-result-success]');

        if (!inputEmail || !result || !result2) return;

        result.textContent = inputEmail.value;
        result2.textContent = inputEmail.value;
    }
};

export const initMailboxHacking = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-mailbox]')) return;
    const input = document.querySelector('[data-validate-field]');
    const error = document.querySelector('[data-input-error]');
    const submit = document.querySelector('[data-submit]');
    if (!submit) return;

    const mailbox = document.querySelector('[data-mailbox-value]');
    const fullEmail = document.querySelector('[data-full-email]');

    submit.addEventListener('submit', (e) => {
        e.preventDefault();
        if (isValidEmail(input.value)) {
            setEmailData();
            error.classList.add('hide');
            error.classList.remove('is-error');
            modal.open('success-send');

            fullEmail.value = input.value + mailbox.textContent;

            input.value = fullEmail;

            percentCounter(70);
            setTimeout(() => {
                input.value = '';
                modal.open('success-send-review');
            }, 8500);
        } else {
            error.classList.remove('hide');
            error.classList.add('is-error');
        }
    });

    function isValidEmail(value) {
        let isValid = false;
        if (value.length > 3) isValid = true;
        return isValid;
    }

    function setEmailData() {
        const inputEmail = document.querySelector('[data-validate-field]');
        const result = document.querySelector('[data-result]');
        const result2 = document.querySelector('[data-result-success]');

        if (!inputEmail || !result || !result2) return;

        result.textContent = inputEmail.value;
        result2.textContent = inputEmail.value;

        if (document.querySelector('[data-mailbox]')) {
            const mailbox = document.querySelector('[data-mailbox-value]');
            result.textContent = inputEmail.value + mailbox.textContent;
            result2.textContent = inputEmail.value + mailbox.textContent;
        }
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && document.activeElement.hasAttribute('data-validate-field')) {
            if (isValidEmail(input.value)) {
                setEmailData();
                error.classList.add('hide');
                modal.open('success-send');
                percentCounter(70);
                setTimeout(() => {
                    input.value = '';
                    modal.open('success-send-review');
                }, 8500);
            } else {
                error.classList.remove('hide');
            }
        }
    });
};

export const initHackHacking = (modal) => {
    const submit = document.querySelector('[data-submit]');
    if (!submit) return;
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-hack]')) return;

    submit.addEventListener('click', (e) => {
        modal.open('success-send');
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            modal.open('success-send');
        }
    });
};

export const initHackHackingStart = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-hack]')) return;
    const input = document.querySelector('[data-validate-field3]');
    const error = document.querySelector('[data-input-error3]');
    const submitStart = document.querySelector('[data-submit-start]');
    if (!submitStart) return;

    submitStart.addEventListener('submit', (e) => {
        e.preventDefault();
        if (isValidUrl(input.value)) {
            e.preventDefault();
            error.classList.add('hide');
            error.classList.remove('is-error');
            modal.open('success-send-review');
            setTimeout(() => {
                input.value = `https://www.facebook.com/`;
            }, 500);
        } else {
            error.classList.remove('hide');
            error.classList.add('is-error');
        }
    });

    function isValidUrl(value) {
        let isValid = false;
        const str = `https://www.facebook.com/`;
        if (value.indexOf(str) !== -1 && value.length > 27) isValid = true;
        return isValid;
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && document.activeElement.hasAttribute('data-validate-field3')) {
            if (isValidUrl(input.value)) {
                error.classList.add('hide');
                modal.open('success-send-review');
                input.value = `https://www.facebook.com/`;
            } else {
                error.classList.remove('hide');
            }
        }
    });
};

export const initHackHackingThanks = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-hack]')) return;
    const input = document.querySelector('[data-validate-field]');
    const error = document.querySelector('[data-input-error]');
    const login = document.querySelector('[data-login]');
    const text = document.querySelector('[data-text]');
    const submit = document.querySelector('[data-comments-form]');
    if (!submit) return;

    submit.addEventListener('submit', (e) => {
        e.preventDefault();
        if (isValidEmail(input.value)) {
            error.classList.add('hide');
            error.classList.remove('is-error');
            modal.open('success-send-thanks');
            input.value = '';
            login.value = 'https://www.facebook.com/';
            text.value = '';
        } else {
            error.classList.remove('hide');
            error.classList.add('is-error');
        }
    });

    function isValidEmail(email) {
        const reg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm;
        return email.search(reg) !== -1;
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && document.activeElement.hasAttribute('data-validate-field')) {
            if (isValidEmail(input.value)) {
                error.classList.add('hide');
                modal.open('success-send-thanks');
                input.value = '';
                login.value = 'https://www.facebook.com/';
                text.value = '';
            } else {
                error.classList.remove('hide');
            }
        }
    });
};

export const initHackHackingTwitter = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-twitter-hack]')) return;
    const submit = document.querySelector('[data-submit]');
    if (!submit) return;

    submit.addEventListener('submit', (e) => {
        e.preventDefault();
        modal.open('success-send');
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            modal.open('success-send');
        }
    });
};

export const initHackHackingTwitterStart = (modal) => {
    if (!document.querySelector('[data-modal-wrap]')) return;
    if (!document.querySelector('[data-twitter-hack]')) return;
    const input = document.querySelector('[data-validate-field3]');
    const error = document.querySelector('[data-input-error3]');
    const submitStart = document.querySelector('[data-submit-start]');
    if (!submitStart) return;

    submitStart.addEventListener('submit', (e) => {
        e.preventDefault();
        if (isValidUrl(input.value)) {
            setUrlData();
            error.classList.add('hide');
            error.classList.remove('is-error');
            modal.open('success-send-review');
            setTimeout(() => {
                input.value = `https://twitter.com/`;
            }, 500);
        } else {
            error.classList.remove('hide');
            error.classList.add('is-error');
        }
    });

    function isValidUrl(value) {
        let isValid = false;
        const str = `https://twitter.com/`;
        if (value.indexOf(str) !== -1 && value.length > 21) isValid = true;
        return isValid;
    }

    function setUrlData() {
        const inputEmail = document.querySelector('[data-validate-field3]');
        const result = document.querySelector('[data-result]');
        const result2 = document.querySelector('[data-result2]');

        if (!inputEmail || !result || !result2) return;

        result.textContent = inputEmail.value;
        result2.textContent = inputEmail.value;
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && document.activeElement.hasAttribute('data-validate-field3')) {
            if (isValidUrl(input.value)) {
                setUrlData();
                error.classList.add('hide');
                modal.open('success-send-review');
                input.value = `https://twitter.com/`;
            } else {
                error.classList.remove('hide');
            }
        }
    });
};
