export const initLangSwitcher = () => {
    // VARS
    const langMenu = document.querySelector('[data-dropdown-menu]');
    if (!langMenu) return;
    const langButton = document.querySelector('[data-dropdown-menu-button]');

    // LISTENERS
    langButton?.addEventListener('click', handleOnClickButton, false);

    document.addEventListener('click', handleOnClickDocument, false);

    document.addEventListener('scroll', handleOnScroll, false);

    document.addEventListener('keydown', handleOnKeydown, false);

    // HANDLERS
    function handleOnClickButton() {
        toggleDropDown();
    }

    function handleOnClickDocument(event) {
        if (!event.target.closest('[data-dropdown-menu]')) {
            closeDropDown();
        }
    }

    function handleOnScroll() {
        closeDropDown();
    }

    function handleOnKeydown(event) {
        if (event.key === 'Escape') {
            closeDropDown();
        }
    }

    // FUNCTIONS & METHODS
    function toggleDropDown() {
        langMenu.classList.contains('collapse') ? closeDropDown() : openDropDown();
    }

    function openDropDown() {
        langMenu.classList.add('collapse');
    }

    function closeDropDown() {
        langMenu.classList.remove('collapse');
    }
};
