import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export const initInstagramHacking = (modal) => {
    const formSelector = '[data-instagram-username-form]';
    const form = document.querySelector(formSelector);
    if (!form) return;
    const profileInput = form.querySelector('[data-profile-input]');
    const profileInputWrap = form.querySelector('[data-profile-input-wrap]');
    const classLinkIcon = 'profile-input-wrap--link';
    const errorElemEmptyField = form.querySelector('[data-input-error="empty-field"]');
    const errorElemWrongFormat = form.querySelector('[data-input-error="wrong-format"]');
    const result = document.querySelector('[data-result]');
    const resultSuccess = document.querySelector('[data-result-success]');
    const instagramDomain1 = 'https://instagram.com/';
    const instagramDomain2 = 'https://www.instagram.com/';
    const minLengthUserName = 1;
    const maxLengthUserName = 50;
    const planForm = document.querySelector('[data-hack-plan-form]');
    const email = planForm.querySelector('[data-plan-form-email]');
    const errorRequired = planForm.querySelector('[data-error-required]');
    const errorInvalid = planForm.querySelector('[data-error-invalid]');
    let isValidForm = true;

    // INITS
    profileInput.value = '';

    // EVENTS
    form.addEventListener('submit', handleOnSubmit, false);

    profileInput.addEventListener('input', handleOnInput, false);

    planForm.addEventListener('submit', handleOnSubmitPlanForm);

    email.addEventListener('focus', handleOnActionEmailInput);

    email.addEventListener('input', handleOnActionEmailInput);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateForm();

        if (isValidForm) {
            const value = profileInput.value.trim();
            setUrlData(value);
            modal.open('success-send');
            isValidForm = true;
        }
    }

    function handleOnInput() {
        const value = this.value.trim();
        toggleIcon(value);
    }

    function handleOnSubmitPlanForm(event) {
        event.preventDefault();

        if (!email.value.trim().length) {
            errorRequired.textContent = errorRequired.dataset.errorRequired;
            errorRequired.classList.add('is-error');
            email.classList.add('is-error');
        } else if (!isValidMail(email.value.trim())) {
            errorInvalid.textContent = errorInvalid.dataset.errorInvalid;
            errorInvalid.classList.add('is-error');
            email.classList.add('is-error');
        } else {
            redirect(planForm);
        }
    }

    function handleOnActionEmailInput() {
        this.classList.remove('is-error');
        errorInvalid.classList.remove('is-error');
        errorRequired.classList.remove('is-error');
    }

    // FUNCTIONS
    function toggleIcon(value) {
        value.indexOf('https') === 0
            ? profileInputWrap.classList.add(classLinkIcon)
            : profileInputWrap.classList.remove(classLinkIcon);
    }

    function validateForm() {
        hideErrors();
        validateProfileInput();
    }

    function validateProfileInput() {
        const inputValue = profileInput.value.trim();
        isValidForm = true;

        if (inputValue.length <= minLengthUserName) {
            isValidForm = false;
            showEmptyFieldError();
        } else if (inputValue.indexOf('https://') === 0) {
            const url = new URL(inputValue);

            if (url.hostname !== 'instagram.com' && url.hostname !== 'www.instagram.com') {
                isValidForm = false;
                showWrongFormatError();
            }
        } else if (!inputValue.startsWith('@') && !/^[A-Za-z0-9._]+$/.test(inputValue)) {
            isValidForm = false;
            showWrongFormatError();
        } else if (inputValue.length > maxLengthUserName) {
            isValidForm = false;
            showWrongFormatError();
        }
    }

    function showEmptyFieldError() {
        errorElemEmptyField.classList.remove('hide');
        errorElemEmptyField.classList.add('is-error');
    }

    function showWrongFormatError() {
        errorElemWrongFormat.classList.remove('hide');
        errorElemWrongFormat.classList.add('is-error');
    }

    function hideErrors() {
        errorElemEmptyField.classList.add('hide');
        errorElemWrongFormat.classList.add('hide');
        errorElemEmptyField.classList.remove('is-error');
        errorElemWrongFormat.classList.remove('is-error');
    }

    function setUrlData(value) {
        let currentValue = value;

        if (currentValue.indexOf('@') !== 0) {
            currentValue = `@${currentValue}`;
        }

        if (currentValue.indexOf(instagramDomain1) === 0 || currentValue.indexOf(instagramDomain2) === 0) {
            currentValue = currentValue.replace(instagramDomain1, '');
            currentValue = currentValue.replace(instagramDomain2, '');
        }

        if (!profileInput || !result || !resultSuccess) return;

        result.textContent = currentValue;
        resultSuccess.textContent = currentValue;
    }
};
