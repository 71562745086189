export const initRenderPricesPage = () => {
    // INITS
    setEmailLocalstorage();
    setDeviceLocalstorage();
    renderPricePageElements();
};

function setEmailLocalstorage() {
    const forms = document.querySelectorAll('[data-localstorage-form-email]');
    if (!forms.length) return;

    [...forms].forEach((form) => {
        const input = form.querySelector('[data-localstorage-email]');

        form.addEventListener(
            'submit',
            function () {
                localStorage.setItem('user-email', input.value.trim());
            },
            false,
        );
    });
}

function setDeviceLocalstorage() {
    const devices = document.querySelectorAll('[data-localstorege-device]');

    devices.forEach((device) => {
        device.addEventListener('click', function () {
            localStorage.setItem('user-device', this.dataset.localstoregeDevice);
        });
    });
}

function renderPricePageElements() {
    renderEmail();
    renderAva();
    renderFeatureList();
}

function renderEmail() {
    const email = localStorage.getItem('user-email');
    const userEmailElement = document.querySelector('[data-user-email]');
    if (!email || !userEmailElement) {
        const userInfo = document.querySelector('[data-user-info]');
        if (userInfo) {
            userInfo.style.display = 'none';
        }
        return;
    }
    const activeClass = 'header-user-info__email--visible';

    userEmailElement.textContent = email;
    userEmailElement.classList.add(activeClass);
}

function renderAva() {
    const email = localStorage.getItem('user-email');
    const userAvaElement = document.querySelector('[data-user-email-char]');
    if (!email || !userAvaElement) return;
    const activeClass = 'header-user-info__ava--visible';

    userAvaElement.textContent = email.substring(0, 1).toLocaleUpperCase();
    userAvaElement.classList.add(activeClass);
}

function renderFeatureList() {
    const device = localStorage.getItem('user-device');
    const activeClass = 'aside-feature-menu__list--visible';
    const featureList = document.querySelector(`[data-feature-list="${device}"]`);

    if (!device) {
        const androidFeatureList = document.querySelector(`[data-feature-list="android"]`);
        if (androidFeatureList) {
            androidFeatureList.classList.add(activeClass);
        }
        return;
    }

    if (featureList) {
        featureList.classList.add(activeClass);
    }
}
