export function Hamburger() {
    // VARS
    const $this = this;
    const hamburger = document.querySelector('[data-hamburger]');
    const activeClass = 'hamburger-v1--active';

    // LISTENERS
    hamburger?.addEventListener('click', handleOnClickHamburger, false);

    // HANDLERS
    function handleOnClickHamburger() {
        $this.toggle();
    }

    // FUNCTIONS & METHODS
    $this.toggle = () => {
        if (hamburger.classList.contains(activeClass)) {
            $this.close();
        } else {
            $this.open();
        }
    };

    $this.open = () => {
        hamburger.classList.add(activeClass);
        disableScroll();
    };

    $this.close = () => {
        hamburger.classList.remove(activeClass);
        enableScroll();
    };
}
