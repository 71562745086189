let accordion = {
    init: function (target) {
        let headers = document.querySelectorAll('.faq-item');
        if (headers.length > 0) {
            for (let head of headers) {
                head.addEventListener('click', accordion.select);
            }
        }
    },

    select: function () {
        this.classList.toggle('collapsed');
    },
};

// features-accordion
let featuresAccordion = {
    init: function (target) {
        let headers = document.querySelectorAll('.features__list-item');
        if (headers.length > 0) {
            for (let head of headers) {
                head.addEventListener('click', featuresAccordion.select);
            }
        }
    },

    select: function () {
        this.classList.toggle('toggle');
    },
};

// footer-accordion
let footerAccordion = {
    init: function (target) {
        let description = document.querySelector('.footer__description');
        let btn = document.querySelector(target);
        if (btn) {
            btn.addEventListener('click', (e) => {
                description.classList.toggle('collapsed');
            });
        }
    },
};

// faq-accordion init
accordion.init('.faq-wrapper');

// features-accordion init
featuresAccordion.init('.features__list');

// footer-accordion
footerAccordion.init('.button__footer');
