import { setCookie } from '../helper-function/coockie/setCookie';
import { getCookie } from '../helper-function/coockie/getCookie';

export const initCookieBanner = () => {
    // VARS
    const cookieBanner = document.querySelector('[data-cookie-banner]');
    const cookieBtnClose = document.querySelector('[data-cookie-apply]');
    if (!cookieBtnClose || !cookieBanner) return;

    // INITS & LISTENERS
    if (!getCookie('cookie-policy')) {
        showCookieBanner();
        cookieBtnClose.addEventListener('click', handleOnCloseCookieBanner, false);
    }

    // HANDLERS
    function handleOnCloseCookieBanner() {
        setCookie('cookie-policy', 'apply');
        hideCookieBanner();
    }

    // FUNCTIONS
    function showCookieBanner() {
        cookieBanner.classList.remove('hidden');
    }

    function hideCookieBanner() {
        cookieBanner.classList.add('hidden');
    }
};
